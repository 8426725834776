@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
:root {
  --color-background: #4075ff;
  --color-general: #f9fbff;
  --color-warning: #fed3bd;
  --color-warning-text: #f88278;
  --color-imgInput-background: #f9fbff;
  --color-passed: #7cd227;
  --color-gray: #939494;
}

.ui.left.aligned.header {
  text-align: left;
  font-weight: bold;
}

i.icon.chevron.left:before {
  content: "\f053";
  color: blue;
}

.ui.vertical.menu .active.item,
.ui.vertical.menu > .active.item:last-child {
  background: #d2e6ff !important;
  border-radius: 20px;
  color: #444 !important;
}

.colorWhite {
  color: #fff;
}

.fullHeight {
  height: 100%;
}
.comms-over {
  overflow: hidden !important;
}
.grayBg {
  background: #ffff;
  width: 33.5% !important;
  float: right;
  position: fixed;
  right: 0;
  height: 100%;
  padding: 0;
}
.grayBg2 {
  width: 33.5% !important;
  top: 50px;
  right: 0;
  height: 98vh;
}
.centerText {
  text-align: center;
}
.calendar_small,
.calendar_small_active {
  cursor: pointer;
}
.calendar_small .rbc-header,
.calendar_small_active .rbc-header {
  color: #4075ff;
  border: 0;
}
.calendar_small .rbc-day-bg,
.calendar_small_active .rbc-day-bg {
  flex: 0 1;
  border: 0;
}
.calendar_small .holidayCToolBar li:nth-child(2),
.calendar_small_active .holidayCToolBar li:nth-child(2) {
  border-left: 0;
  border-right: 0;
  color: #4075ff !important;
}

.calendar_small .rbc-row-segment .rbc-event,
.calendar_small_active .rbc-row-segment .rbc-event {
  position: relative;
  background: #4441f1 !important;
  width: 1px !important;
  height: 1px !important;
  top: -5px;
  left: -5px;
  z-index: 999;
}

.calendar_small .rbc-row-content .rbc-row .rbc-date-cell.rbc-off-range,
.calendar_small_active .rbc-row-content .rbc-row .rbc-date-cell.rbc-off-range {
  color: #cecece !important;
}
.calendar_small .rbc-row-content .rbc-row .rbc-date-cell,
.calendar_small_active .rbc-row-content .rbc-row .rbc-date-cell {
  color: #000;
  padding: 10px;
  border: 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center !important;
  width: 45px !important;
  height: 45px !important;
}

.calendar_small .rbc-month-row,
.calendar_small_active .rbc-month-row {
  border: none;
}
.calendar_small .rbc-day-bg + .rbc-day-bg,
.calendar_small_active .rbc-day-bg + .rbc-day-bg {
  border-left: 0;
}

/* .rbc-date-cell {
  z-index: -1;
} */
.calendar_small .rbc-month-row + .rbc-month-row,
.calendar_small_active .rbc-month-row + .rbc-month-row {
  border-top: none !important;
}
.calendar_small .rbc-header + .rbc-header,
.calendar_small_active .rbc-header + .rbc-header {
  border: none;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdddf;
}
.calendar_small .rbc-date-cell.rbc-now {
  position: relative;
  color: white !important;
}
.calendar_small_active .rbc-date-cell.rbc-now {
  color: #4075ff !important;
}
.calendar_small_active .rbc-current {
  position: relative !important;
  color: #4075ff !important;
  background-color: #4075ff !important;
  text-align: center !important;
  border-radius: 100%;
  height: 35px !important;
}

@media screen and (max-width: 1024px) {
  .calendar_small_active .rbc-current {
    position: relative !important;
    color: #4075ff !important;
    background-color: #4075ff !important;
    text-align: center !important;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 35px !important;
    height: 35px !important;
  }
}

@media screen and (max-width: 768px) {
  .calendar_small_active .rbc-current {
    position: relative !important;
    color: #4075ff !important;
    background-color: #4075ff !important;
    text-align: center !important;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 26px !important;
    margin-top: 6px;
    margin-left: 6px;
    right: 0;
    height: 35px !important;
  }
}
.calendar_small .rbc-now.rbc-current {
  color: white !important;
  background-color: #4075ff !important;
  text-align: center !important;
  width: 20px !important;
  height: 10px !important;
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .calendar_small .rbc-now.rbc-current {
    color: white !important;
    background-color: #4075ff !important;
    text-align: center !important;
    width: 20px !important;
    height: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 6px; */
    max-height: 35px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 768px) {
  .calendar_small .rbc-now.rbc-current {
    color: white !important;
    background-color: #4075ff !important;
    text-align: center !important;
    width: 20px !important;
    height: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    margin-top: 6px;
    max-height: 29px;
    margin-right: 0;
    border-radius: 50%;
  }
}

.calendar_small_active .rbc-now.rbc-current {
  color: #4075ff !important;
  background-color: #4075ff !important;
  text-align: center !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}

.calendar_small_active .rbc-current::before {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  width: 0px !important;
  height: 0px !important;
  border-radius: 50%;
  background-color: white !important;
  z-index: -1;
}

.calendar_small .rbc-date-cell.rbc-now::before {
  clear: both;
  content: "";
  position: absolute;
  top: 0;
  left: 0px;
  width: 0px !important;
  height: 0px !important;
  border-radius: 50%;
  background-color: white !important;
  z-index: -1;
}

.logo {
  width: 166px !important;
  margin: 35px 0 17px 10px;
}
.rbc-allday-cell {
  display: none;
}
.rbc-today {
  background-color: transparent !important;
}
.rbc-time-header-gutter {
  height: 0px;
}
.rbc-time-view {
  background-color: white;
}
.rbc-time-view .rbc-header {
  border: none !important;
  border-bottom: none;
}
.rbc-time-content {
  border: none !important;
  padding-top: 10px;
  padding-left: 10px;
}
.rbc-current-time-indicator {
  display: none;
}
.rbc-time-header-content {
  border-left: none !important;
}
.rbc-row {
  max-height: 90px;
}
.rbc-event {
  border: none !important;
  background-color: transparent !important;
}
.rbc-event-content {
  background-color: transparent !important;
  min-height: 50px !important;
}
.rbc-time-column .rbc-timeslot-group {
  border-bottom: none !important;
  border-left: none !important;
  border-top: 1px solid #dcdddf !important;
}
.rbc-time-slot {
  border-top: none !important;
}
.rbc-day-slot .rbc-events-container {
  border: none;
  margin: 0px !important;
}
.rbc-time-header {
  margin-left: 10px;
}
.rbc-day-slot .rbc-event-label {
  display: none;
}
.rbc-label {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  color: #939494;
  padding: 0 5px;
  position: relative;
  text-align: right;
  top: -11px;
  background-color: white;
}
.calendar_header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #939494;
  position: relative;
  top: 25px;
}
.calendar_header_date {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #939494;
  position: relative;
  top: 15px;
}
.calendar_small,
.calendar_small_active {
  cursor: pointer;
}
.calendar_small .rbc-header,
.calendar_small_active .rbc-header {
  color: #4075ff;
  border: 0;
}
.calendar_small .rbc-day-bg,
.calendar_small_active .rbc-day-bg {
  flex: 0 1;
  border: 0;
}

.calendar_small .rbc-row-segment .rbc-event,
.calendar_small_active .rbc-row-segment .rbc-event {
  background: #f1bd41 !important;
  width: 1px !important;
  height: 1px !important;
  margin-top: -10px;
  margin-left: 24px;
}

@media screen and (max-width: 425px) {
  .calendar_small .rbc-row-segment .rbc-event,
  .calendar_small_active .rbc-row-segment .rbc-event {
    background: #f1bd41 !important;
    width: 1px !important;
    height: 1px !important;
    margin-top: -10px;
    margin-left: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .calendar_small .rbc-row-segment .rbc-event,
  .calendar_small_active .rbc-row-segment .rbc-event {
    background: #f1bd41 !important;
    width: 1px !important;
    height: 1px !important;
    margin-top: -10px;
    margin-left: 19px;
  }
}

.calendar_small .rbc-row-content .rbc-row .rbc-date-cell.rbc-off-range,
.calendar_small_active .rbc-row-content .rbc-row .rbc-date-cell.rbc-off-range {
  color: #cecece !important;
}
.calendar_small .rbc-row-content .rbc-row .rbc-date-cell,
.calendar_small_active .rbc-row-content .rbc-row .rbc-date-cell {
  color: #000;
  padding: 10px;
  border: 0;
  font-weight: 600;
  font-size: 14px;
}
.calendar_small .rbc-month-row,
.calendar_small_active .rbc-month-row {
  border: none;
}
.calendar_small .rbc-day-bg + .rbc-day-bg,
.calendar_small_active .rbc-day-bg + .rbc-day-bg {
  border-left: 0;
}

/* .rbc-date-cell {
    z-index: -1;
  } */
.calendar_small .rbc-month-row + .rbc-month-row,
.calendar_small_active .rbc-month-row + .rbc-month-row {
  border-top: none !important;
}
.calendar_small .rbc-header + .rbc-header,
.calendar_small_active .rbc-header + .rbc-header {
  border: none;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdddf;
}
.calendar_small .rbc-date-cell.rbc-now {
  position: relative;
  color: white !important;
}
.calendar_small_active .rbc-date-cell.rbc-now {
  color: #4075ff !important;
}
.calendar_small_active .rbc-current {
  position: relative;
  color: white !important;
}
.calendar_small_active .rbc-now.rbc-current {
  color: white !important;
}

.calendar_small_active .rbc-current::before {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4075ff;
  z-index: -1;
}
.calendar_small .rbc-date-cell.rbc-now::before {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4075ff;
  z-index: -1;
}
.ui.segment.segmentCustommize {
  padding: 0 2em;
  margin: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border: 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
}

.ui.segment.segmentCustommize .ui[class*="right floated"].header,
.ui.segment.segmentCustommize .ui[class*="left floated"].header {
  margin-top: 0.6em;
}

.main-content-width {
  min-width: 600px;
  margin-left: 240px;
  background: #f9fbff;
  height: 100%;
  padding: 0 !important;
}

.row {
  height: 100%;
  display: table-row;
}

.ui.menu.sidebarMenu {
  color: black;
  border: 0;
  margin: 0 0 0 0;
  flex-direction: column;
  top: 0px;
  bottom: -10px;
  left: 0px;
  /*background: var(--color-background)!important;*/
  position: fixed;
  width: 240px !important;
  flex: 1 1;
  overflow: auto;
  z-index: 9;
  padding: 22px;
  padding-bottom: 35px;
}

.ui.menu.sidebarMenu .item {
  font-weight: 700;
  font-size: 14px;
  padding-left: 15px;
  margin: auto;
  height: 48px;
  margin: auto;
  line-height: 24px !important;
  color: gray;
}

.ui.vertical.menu.sidebarMenu > .active.item:first-child {
  border-radius: 0;
}

.main-content-width > .ui.grid.fullHeight {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ui.grid.fullHeight > .row {
  padding: 8px 13px 13px 13px !important;
}

.ui.grid.fullHeight > .row h3 {
  padding-top: 8px;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 29px;
  color: #4075ff !important;
  color: var(--color-background) !important;
}

.primaryBgColor {
  background-color: #4075ff !important;
  background-color: var(--color-background) !important;
}

.primaryBtn {
  background-color: #4075ff !important;
  background-color: var(--color-background) !important;
  font-size: 1.05rem !important;
  border-radius: 20px !important;
  /*width: 125px;*/
  height: 40px;
}

.defaultBtn {
  color: #4075ff !important;
  color: var(--color-background) !important;
  font-size: 1rem !important;
  border-radius: 1.7rem !important;
  border: 1px solid #4075ff !important;
  background-color: transparent !important;
  min-width: 179px;
}

.fullWidth {
  width: 100%;
}

.fullHeight1 {
  margin: 0 !important;
}

.noBottomMargin {
  margin-bottom: 0 !important;
}

.ui.active.button.defaultBtn {
  background-color: #4075ff !important;
  background-color: var(--color-background) !important;
  color: #fff !important;
}

.ui.button.defaultBtn {
  margin-right: 10px;
}

.ui.grid > .list-title-container {
  margin-left: 1rem;
  margin-right: 1rem;
}

.ui.grid.activityTitle {
  padding: 0 1em 0 1em;
}

.ui.blue.progress:last-child {
  margin: 0 0 0.5em;
}

.regStatusMessage {
  background: #d2e6ff;
  text-align: center;
  padding: 8px 6px !important;
  border-radius: 20px;
  margin-top: -4px;
  color: #444;
}

.appMaintenanceContainer {
  background-color: white;
  border-radius: 5;
  text-align: center;
  padding: 20;
}

.regOpenMessage {
  padding: 6px 13px;
  color: #166818;
  background: #d9eed5;
  border-radius: 16px;
}

.regCloseMessage {
  padding: 6px 13px;
  color: #4075ff;
  background: #e6f4ff;
  border-radius: 16px;
}

.ui.segment.listBox {
  font-weight: 800;
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); */
  border-radius: 8px;
  background: #ffff;
  min-height: 64px;
}

.ui.segment.listbox-border {
  width: 170px;
  background: #e6f4ff;
  border: 1px solid#4075FF;
  color: #3c73dc;
}

.ui.segment.listbox-border2 {
  background: #f9fbff;
  border: 1px solid#4075FF;
}

.nofontWeight {
  font-weight: normal;
}

.backChevron {
  font-size: 1.2rem !important;
  opacity: 1 !important;
  width: 0.44em !important;
}

.ui.basic.backBtn.button {
  font-size: 20px !important;
  color: #4075ff !important;
  color: var(--color-background) !important;
  font-weight: 800;
  box-shadow: none !important;
  padding: 8px 0;
}

.ui.grid.subContainer {
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 28px 0 31px 0 !important;
  border-radius: 20px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  background: #fff;
  padding-top: 51px;
  height: 90%;
  padding-bottom: 40px;
}

.formMargin {
  margin: 8px 0 24px 0px;
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}
.dropdown2 .Dropdown-control {
  border: 1px solid #dadada;
  border-radius: 8px;
  height: 44px;
  line-height: 30px;
}
.dropdown2 .Dropdown-placeholder {
  color: #dadada;
}
.formMargin2 {
  margin: 5px 0 24px 0px;
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.formLabel {
  font-size: 16px;
  color: #000;
  font-size: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.formLabel2 {
  font-size: 16px;
  color: #000;
  font-size: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  position: relative;
  top: -5px;
}
.formMargin textarea,
.react-datepicker__input-container input[type="text"] {
  margin: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 8px;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
  width: 100%;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.formMargin textarea:-ms-input-placeholder {
  opacity: 0.4;
}

.formMargin textarea::placeholder {
  opacity: 0.4;
}

label.fileLabel input[type="file"] {
  display: none;
}

.fileLabel {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: inline-block;
  border-radius: 8px;
  border: 1px solid #dadada;
}

label.fileLabel2 input[type="file"] {
  display: none;
}

.fileLabel2 {
  height: 20vh;
  width: 100%;
  border-radius: 8px;
  border: 2px dashed #4075ff;
  background-size: cover;
  background-position: center;
  display: inline-block;
}

label.fileLabel3 input[type="file"] {
  display: none;
}

.audio,
video {
  border-radius: 8px;
  height: 98% !important;
}

.fileLabel3 {
  width: 100%;
  height: 20vh;
  border-radius: 8px;
  border: 2px dashed #4075ff;
  display: inline-block;
}

/*https://stackoverflow.com/questions/4641752/css-how-to-style-a-selected-radio-buttons-label*/
.radio-toolbar .ui.radio {
  display: none;
}

.radio-toolbar span {
  display: inline-block !important;
  padding: 11px 22px !important;
  cursor: pointer !important;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  text-align: center;
}

.radio-toolbar .ui.checked + span {
  background: #e6f1fc;
  border: 1px solid #065ca4;
  color: #065ca4;
}
.radio_button .ui.radio.checkbox input:focus:checked ~ label:after {
  background-color: #4075ff !important;
  height: 20px;
  width: 20px;
}
.radio_button .ui.radio.checkbox label:after {
  background-color: #4075ff;
  width: 20px;
  height: 20px;
}
.radio_button .ui.checkbox input:checked ~ label:before {
  height: 20px;
  width: 20px;
}
.radio_button .ui.radio.checkbox input:checked ~ label:after {
  background-color: #4075ff;
  height: 20px;
  width: 20px;
}
.radio_button .ui.checkbox label:before {
  border: 2px solid #4075ff;
  height: 20px;
  width: 20px;
}
.radio_button .ui.checkbox input:checked ~ label:before {
  border: 2px solid #4075ff;
}
.specific .react-datepicker__input-container input {
  padding-left: 10px !important;
}
.specific_slot .ui.image {
  margin-left: 17px;
}
.radio_button .ui.checkbox input.hidden + label {
  text-decoration: none;
  color: #000;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.radio_button .ui.radio.checkbox {
  min-height: 21px;
}
.radio_button .checked {
  background: none;
}
.radio-toolbar .ui.checked + span img {
  /* filter: invert(67%) sepia(45%) saturate(6346%) hue-rotate(187deg)
    brightness(100%) contrast(102%); 
   filter: invert(77%) sepia(68%) saturate(5211%) hue-rotate(177deg)
    brightness(79%) contrast(90%); 
   filter: invert(57%) sepia(93%) saturate(2390%) hue-rotate(178deg)
    brightness(93%) contrast(83%);  */
  /* filter: invert(80%) sepia(31%) saturate(4625%) hue-rotate(213deg)
    brightness(92%) contrast(92%); */
  filter: invert(36%) sepia(82%) saturate(3019%) hue-rotate(216deg)
    brightness(106%) contrast(102%);
}

.react-datepicker-wrapper {
  width: 100%;
}

.imgContainer {
  width: 100%;
  height: 200px;
  /*line-height: 242px!important;*/
  text-align: center;
  vertical-align: middle;
  background-size: cover;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.imgContainer-sm {
  margin-top: 8px;
  width: 64px;
  height: 64px;
  background: #dadada;
  /*line-height: 242px!important;*/
  text-align: center;
  vertical-align: middle;
  background-size: cover;
  border-radius: 8px;
  border: 1px solid #ddd;
}
/*.ui.grid>.row>[class*="six wide"].column.grayBg, .ui.grid>.row>[class*="four wide"].column.grayBg {
    position: absolute;
    top: 0!important;
    right: 0!important;
    bottom: 0!important;
    margin-top: -103px;
    padding-top: 116px;
    padding-bottom: 2rem;
    margin-right: -1rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }*/
.ui.dropdown > .dropdown.icon:before {
  content: "\f078" !important;
  font-size: 20px;
  color: #6600ff;
  font-weight: 800;
}

.ui.selection.dropdown2 .menu > .item {
  height: 45px;
}

.ui.modal .text {
  height: 0 !important;
}

.ui.basic.button.simpleRouter {
  box-shadow: none !important;
  font-size: 13px !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 700 !important;
  text-transform: none !important;
  color: rgba(0, 0, 0, 0.87) !important;
  padding-top: 35px;
  padding-bottom: 0 !important;
  text-decoration: underline;
}

.authFom .ui.basic.backBtn.button,
.authFom h3.ui.header {
  font-size: 1.28571429rem !important;
  color: #000 !important;
}

.authFom .ui.message {
  padding: 8px 20px;
  border-radius: 20px;
  text-align: left;
}

.authFom .ui.message .ui.grid > .row {
  padding: 0 !important;
}

.authFom .ui.message {
  background: #e6f1fc;
  border: 1px solid #065ca4;
  margin-top: 4px;
}

.ui.checkbox input.hidden + label {
  color: #065ca4;
  text-decoration: underline;
}

.ui.vertical.menu.sidebarMenu .item::before {
  height: 0 !important;
}

.ui.menu.sidebarMenu a.item:hover {
  cursor: pointer;
  background: transparent;
  color: gray;
}

.ui.basic.backBtn.button:hover {
  background-color: transparent;
}

.ui.dropdown > .dropdown.icon:before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAJCAYAAAACTR1pAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACKSURBVHgBhc7bCYUwDAbgpB7kjNFV3EIfBJ1MX0S3cBQ7hohWkwjiJdYf8tAkXwnGeTMAxUQmGevMQSD/orV+8b3sg/cA6yoNHnwi2mVjpmVO6OFC+IJolw3KJK1sHP16QLRU7ny2hqArHR7fKpjbGuI+Xm66Yekp6AnveIcPpMMzpmgoHMJSL9kArI98UFk1ugoAAAAASUVORK5CYII=) !important;
}

.removeBtn {
  right: 0;
  position: absolute;
  background: #4075ff;
  padding: 8px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADfSURBVHgBndSLDYIwEAbgqxOwgR2BDXQEN5AR3EA2cAR1EnQCRugIuEG9C20gzd318ScXoLQfbZMC3vsO64JloTHRiA+zX7NgnaEyNCaMpUywe4i5VmBDMnbhGim3BmybjPByVLBRxIS9EFEGozEn6cu9hgpYD5m9sVguRRnMZbEMmmIWaqKgKnZQzF+o0nZ1dnSUZmXJ9K4rxSyDzUyby+6jsG9TmHEX7stQAXsy/V5ZtBTb9X+IqF9PR4rdIRPqw6A9tGAKuqRgMSagzoS106/nY4z5QkPQGPByxHr/AT/82a9zsMJqAAAAAElFTkSuQmCC) no-repeat center center;
  border-top-right-radius: 13px;
}

.ui.celled.list.peopleList > .item {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  padding: 20px 13px;
}

.ui.celled.list.peopleList > .item .header {
  font-size: 16px;
  margin-left: 36px;
  margin-top: 15px;
}

.child {
  margin-top: 33px !important;
  position: relative !important;
  margin-left: -18px !important;
  z-index: 1 !important;
  width: 36px !important;
  height: 36px !important;
  overflow: scroll !important;
}

.submitBtn {
  background-color: #4075ff !important;
  background-color: var(--color-background) !important;
  font-size: 14px !important;
  border-radius: 20px !important;
  width: 236px;
  height: 48px;
}

.calendar_filter {
  width: 25% !important;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.DelBtn {
  background-color: #f66460 !important;
  font-size: 14px !important;
  border-radius: 20px !important;
  width: 236px;
  height: 48px;
  margin-right: 40px;
}

.noti-datetime {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #3c73dc;
}

.ui.segment.user-panel {
  height: 112px;
  display: inline-block;
  width: 100%;
  background-color: #4075ff;
  border: 0px;
  border-radius: 20px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  padding-top: 24px;
  padding-left: 32px;
}

.user-image {
  width: 64px;
  height: 64px;
  border: 3px solid #ffff;
  border-radius: 50px;
}

.user-name {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 14px;
  margin-top: 10px;
}

.user-type {
  position: absolute;
  height: 16px;
  left: 78px;
  top: 50px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;

  color: #ffffff;
}

.user-setting {
  position: relative;
  display: inline-flex;
  margin-top: 5px !important;
  background: #e6f4ff !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 20px !important;
  color: #4075ff !important;
  width: 119px;
  height: 48px;
  font-size: 14px !important;
  word-break: normal;
}

.sidebar-icon {
  width: 18px;
  height: 16px;
  margin-right: 11px;
  color: gray;
}

.Toolbar_width {
  width: 15% !important;
}

.active.item .sidebar-icon {
  filter: opacity(1) brightness(0);
}

.content.recentOrder label:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAKCAYAAAB4zEQNAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABySURBVHgBdU/bDYAgDLySGNdgFNjIL53BPzeCTWQN4wdeSeojwUsoTe9y10qY6w6FIOZVCl5wqKwVni+FpfoveSLyLz2BaAkTBwMSW89JsQgxVU/g7oDRfNoOwGG2msO8lqv53CNvtP0j7JQu8dzZIRQXdds73aOdTnwAAAAASUVORK5CYII=);
  margin-left: 4px;
}

.authFom .ui.large.input {
  font-size: 1.14285714em;
  margin-top: -12px;
}

.authFom .ui.fluid.input > input,
.authFom .ui.primary.button {
  border-radius: 20px;
}

.authFom .ui.error.message {
  box-shadow: none !important;
}

.authFom {
  background: url(/static/media/BG.5ae3ba09.png);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.authFom .ui.fluid.input > input,
.authFom .ui.primary.button {
  height: 40px;
}
.adjustWidth {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 25px;
}
.cardInApp {
  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%) !important;
  height: auto !important;
  border: 1px solid #fcfcfc !important;
  border-radius: 5px !important;
  text-align: center;
  padding: 0px !important;
}
.cardImageCenter {
  width: 100%;
  text-align: center;
  background-color: #065ca4;
}
.videoAs.audio,
video {
  width: 100% !important;
  aspect-ratio: 16/9;
}
.preview {
  aspect-ratio: 16/9;
}
.newsfeedCard {
  box-shadow: 0px 0px 1px rgb(0 0 0 / 10%) !important;
  height: auto !important;
  border: 1px solid #fcfcfc !important;
  border-radius: 5px !important;
  padding: 0px !important;
  width: 100% !important;
}
.column.disable-scrollbars.padding0 {
  padding: 0 !important ;
}
.border {
  border: 1px soild #dcdddf !important;
}
.row.padding {
  padding: 0px 0px 0px 0px !important;
}
.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}
.vhCenter {
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  vertical-align: middle;
  padding: 0 auto;
}
.grid-container {
  display: grid;
}
.scrollHidden::-webkit-scrollbar {
  display: none;
}
.profileImage {
  border-radius: 30px;
  border-color: #4075ff;
  border-width: 1px;
  height: 40px;
  width: 80px !important;
  border-style: solid;
}
.borderButton {
  border: medium none currentColor !important;
  border: initial !important;
}
.card {
  position: relative;
  height: 140px;
  left: 20;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #4075ff;
  box-sizing: border-box;
  border-radius: 20px !important;
}
.rc_form .react-datepicker-wrapper .react-datepicker__input-container input {
  font-size: 5 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  display: flex;
  flex-direction: column;
}
/* .card {
  width: 194px;
  height: 140px;
  left: 20;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #4075ff;
  box-sizing: border-box;
  border-radius: 20px;
} */

/* .card-title {
  position: absolute;
  left: 26px;
  top: 29px;
  bottom: 99px;
  height: 22px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
} */
.card-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 10px;
}
/* .card-image {
  position: absolute;
  left: 33px;
  right: 134px;
  width: 48px;
  height: 48px;
  top: 59px;
  bottom: 33px;
} */
.card-image {
  width: 48px;
  height: 48px;
}
/* .card-number {
  position: absolute;
  width: 42px;
  height: 49px;
  left: 138px;
  right: 25;
  top: 50.17px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  color: #000000;
} */
.card-number {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  color: #000000;
}
.card-text {
  position: absolute;
  width: 42px;
  height: 49px;
  right: 25px;
  top: 102.67px;
  color: gray !important;
}
/* .card-text {
  position: absolute;
  width: 42px;
  height: 49px;
  left: 141px;
  right: 25;
  top: 102.67px;
  color: gray !important;
} */

.status-acitve {
  text-align: center;
  padding: 6px 8px;
  margin-top: -4px;
  min-width: 80px;
  height: 32px;
  color: #000;
  background: #d1eaff;
  border-radius: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.status-inacitve {
  text-align: center;
  padding: 6px 8px;
  min-width: 80px;
  height: 32px;
  margin-top: -4px;
  color: #f66460;
  background: #fed3bd;
  border-radius: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.btnadmin {
  width: 133.71px;
  height: 44px;
  background: linear-gradient(0deg, #e6f1fc, #e6f1fc), #ffffff;
  border-radius: 8px;
}

.btnWhite {
  width: 133.71px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 8px;
}

.addBtn {
  width: 194px !important;
  height: 48px !important;
  background: #4075ff !important;
  border-radius: 20px !important;
}

.userImage {
  width: 160px !important;
  height: 160px !important;
  background: #c4c4c4 !important;
}

.infofield {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dadada;
  padding-top: 31px !important;
  padding-bottom: 22px !important;
  padding-left: 4px;
  box-sizing: border-box;
  border-radius: 8px;
}
.meal-list {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dadada;
  padding-top: 11px !important;
  padding-bottom: 10px !important;
  padding-left: 4px;
  box-sizing: border-box;
  border-radius: 8px;
}
.meal-list .image {
  width: 64px;
  height: 64px !important;
  background: #dadada;
  border-radius: 8px;
}

.infoMargin {
  margin-top: 25 !important;
  margin-left: 20 !important;
}

.infodetail {
  width: 617px !important;
  height: 519px !important;
  background: #ffffff;
  align-items: center !important;
  border-radius: 8px !important;
}

.usermodal {
  width: 375px !important;
  height: 431px !important;
  background: #ffffff;
  align-items: center !important;
  align-content: center !important;
}

.userdetailimg {
  position: absolute;
  width: 160px !important;
  top: 40px;
  height: 160px !important;
  border: 4px solid #3c73dc;
  box-sizing: border-box;
}

.userdetailstatus {
  position: absolute;
  width: 77px;
  height: 32px;
  padding: 5px;
  left: 149px;
  top: 222px;
  padding-left: 15px;
  align-items: center;
  background: #fbefd2;
  border-radius: 16px;
}

.userdetailName {
  position: absolute;
  top: 263px;
  left: 136px;
}

.authFom > div {
  width: 400px !important;
  max-height: 640px !important;
  overflow: auto !important;
  background: #f9fbff !important;
  border-radius: 20px !important;
}

.authFom h3.ui.header {
  margin-top: 17px !important;
}

.authFom > div .ui img {
  width: 150px !important;
}

.authFom .ui.header {
  margin: 4px 0 !important;
}

.authFom .ui.primary.button {
  margin-top: 35px !important;
}

.authFom h5.ui.header {
  font-weight: normal;
  margin-bottom: 22px !important;
}

.content.recentOrder label:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAKCAYAAAB4zEQNAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABySURBVHgBdU/bDYAgDLySGNdgFNjIL53BPzeCTWQN4wdeSeojwUsoTe9y10qY6w6FIOZVCl5wqKwVni+FpfoveSLyLz2BaAkTBwMSW89JsQgxVU/g7oDRfNoOwGG2msO8lqv53CNvtP0j7JQu8dzZIRQXdds73aOdTnwAAAAASUVORK5CYII=);
  margin-left: 4px;
}

.content.recentOrder label {
  color: #4075ff !important;
  color: var(--color-background) !important;
  position: absolute;
  top: 40%;
  right: 16px;
}

.content.recentOrder {
  margin-left: 6px !important;
}

.ui.pagination.menu .active.item {
  padding: 13px !important;
  text-align: center !important;
  align-items: center;
}

.ui.secondary.menu .item {
  border-radius: 40px !important;
  align-items: center;
}

.ui.secondary.menu .active.item {
  background-color: #4d7198 !important;
  color: #fff;
  text-align: center !important;
  padding: 0px i !important;
}

.authFom .ui.large.input {
  font-size: 1.14285714em;
  margin-top: -12px;
}

.authFom .ui.fluid.input > input,
.authFom .ui.primary.button {
  border-radius: 20px;
}

.authFom .ui.error.message {
  box-shadow: none !important;
}

.authFom {
  background: url(/static/media/BG.5ae3ba09.png);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.authFom .ui.fluid.input > input,
.authFom .ui.primary.button {
  height: 48px;
}

.ui.grid > .row > [class*="four wide"].column.grayBg.addwhitebg {
  width: 311px !important;
  background: #fff;
}
.ui.grid > .row > [class*="four wide"].column.grayBg2.addwhitebg {
  width: 311px !important;
  background: #fff;
}
.merchandise .ui.fluid.dropdown {
  width: 170px;
  border-radius: 8px;
}

/*.merchandise .listBox{
    height: 160px;
    line-height: 160px;
  }*/
.merchandise .listBox h4,
.merchandise .listBox image,
.listBox p {
  display: inline-block;
}

.merchandise .listBox .column {
  height: 127px;
  line-height: 127px;
}

.merchandise .listBox .column:last-child,
.merchandise .listBox .column:last-child {
  right: 0;
  position: absolute;
  top: 44%;
  line-height: unset;
}

.merchandise .ui.card {
  height: auto !important;
  width: 100%;
}

.ui.grid.fullHeight > .row h2 {
  font-size: 20px;
  font-weight: bold;
}

.ui.segment.listBox.lightenShadow {
  padding: 0.8em 0.8em !important;
  border: none !important;
}

.fullWidthCenterAlign {
  width: 100%;
  text-align: center;
}

.user-setting-icon {
  width: 160px;
  height: 160px;
  margin-top: -30px;
  border: 4px solid #4075ff;
  border-radius: 50%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.button-logout {
  width: 230px;
  height: 48px;
  border-radius: 20px !important;
  background: #4075ff !important;
  color: #fff !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.user-role {
  display: inline-block;
  width: auto;
  margin-top: 14px;
  padding: 5px 17px 5px 17px;
  height: 32px;
  background: #e6f4ff;
  border-radius: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4075ff;
}

.Bedok {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #939494;
}

.noStockWarning {
  background: #fed3bd !important;
  background: var(--color-warning) !important;
  color: #f88278;
  color: var(--color-warning-text);
}

.longcard {
  width: 100%;
  height: 116px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #4075ff;
  box-sizing: border-box;
  border-radius: 20px;
}

.longcard .content .cardTitle {
  position: absolute;
  left: 34px;
  right: 82px;
  top: 29px;
  bottom: 99px;
  height: 22px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #1b1a1a;
}

.longcard .content .cardNumber {
  position: absolute;
  width: 73px;
  height: 54px;
  left: 34px;
  right: 25;
  top: 45px;
  /* font-family: Poppins !important; */
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px !important;
  color: #7cd227;
}

/*/////////////////////////////////////////// FORM  ////////////////////////////////////////*/

.ui.grid.form-subcontainer {
  margin: 28px 0 31px 0 !important;
  border-radius: 8px;
  background: #fff;
  padding-top: 51px;
  padding-bottom: 40px;
}

.ui.segment.forms-listbox {
  font-size: 13px;
  font-weight: 800;
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); */
  border-radius: 8px;
  background: #ffff;
  min-height: 64px;
}

.empty-style-card {
  width: auto;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: "blue";
}

.form-list-empty-cart {
  box-shadow: 1px 0px 2px 0 rgba(34, 36, 38, 0.15) !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 13px;
  color: #dadada;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  justify-content: center;
  align-content: center;
  font-weight: 800;
  padding-top: 30px;
  padding-bottom: 30px;
  vertical-align: middle;
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); */
  border-radius: 8px;
  background-color: #ffff;
}

.filledformlist {
  border: 2px solid #dadada;
  padding: 16px 14px 16px 14px !important;
}

.react-datepicker__input-container input {
  padding-left: 58px !important;
}

.input-iconnnn {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-top: -30px;
  margin-left: 75%;
  /* z-index: 1; */
}

.input-iconnn {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-top: -31px;
  margin-left: 5%;
  /* z-index: 1; */
  color: #4075ff;
}

/*///////////////////////////// DONATION //////////////////////*/
.donation-orderBtn {
  color: #4075ff !important;
  color: var(--color-background) !important;
  font-size: 1rem !important;
  border-radius: 1.7rem !important;
  border: 1px solid #4075ff !important;
  background-color: #fff !important;
  min-width: 160px;
}

.donation-square {
  color: #4075ff !important;
  color: var(--color-background) !important;
  font-size: 1rem !important;
  border-radius: 0.7rem !important;
  border: 1px solid #4075ff !important;
  background-color: #fff !important;
  min-width: 160px;
  margin-left: 5px;
}

.ui.segment.user-card {
  border-radius: 8px;
  border: 1px solid #4075ff;
  background: #f9fbff;
  padding: 50px;
}

.longcard .content .cardbottom {
  position: absolute;
  height: 18px;
  left: 34px;
  top: 93px;

  color: #54b157;
}

.longcard .content .cardbottom .cardtext {
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}
.nice-dates-navigation {
  margin-top: -10px;
}
.nice-dates-week-header_day {
  color: #4075ff !important;
  font-weight: 700 !important;
}

.nice-dates-navigation_previous {
  position: absolute;
  left: 65px;
  top: 2px;
}
.nice-dates-navigation_previous:before {
  border-right: 2px solid #4075ff !important;
  border-top: 2px solid #4075ff !important;
  width: 10px !important;
  height: 10px !important;
}
.nice-dates-navigation_next {
  position: absolute;
  right: 65px;
  top: 2px;
}
.nice-dates-navigation_next:before {
  border-right: 2px solid #4075ff !important;
  border-top: 2px solid #4075ff !important;
  width: 10px !important;
  height: 10px !important;
}
.nice-dates-navigation_current {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #4075ff !important;
}
.nice-dates-day_month {
  opacity: 0 !important;
}
.nice-dates-day_date {
  font-weight: 600 !important;
}
.nice-dates-day.-highlight {
  position: relative;
}
.nice-dates-day.-highlight > .nice-dates-day_date:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #7cd227;
  bottom: 3px;
  z-index: 1;
  border: 0;
  border-radius: 50%;
}
.nice-dates-day:before {
  background-color: #4075ff !important;
}
.nice-dates-day.-absentdate {
  position: relative;
}
.nice-dates-day.-absentdate > .nice-dates-day_date:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #f3b329;
  bottom: 3px;
  z-index: 1;
  left: 20px;
  border: 0;
  border-radius: 50%;
}
.calenderModal {
  width: 480px !important;
  height: 564px !important;
  background: #ffffff;
  margin: 20px !important;
  align-items: center !important;
  align-content: center !important;
}

.ui.modal > .content {
  border-radius: 8px !important;
  padding-bottom: 40px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.tabColor {
  position: absolute;
  top: 60px;
  width: 90px;
  height: 32px;
  background: #d1eaff;
  border-radius: 16px;
  right: 40px;
  text-align: center;
  padding-top: 5px;
}

.multiContentListBox {
  border: 1px solid #ddd;
  border-radius: 4px;
  height: auto;
  overflow: auto;
  max-height: 350px;
}

.multiContentListBox .ui.card {
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  margin: 0 !important;
}

.multiContentListBox .ui.card:last-child {
  border-bottom: 0 !important;
}

.multiContentListBox .ui.card .ui.feed > .event > .content .date {
  margin: 1px 0px 0px;
}

.multiContentListBox
  .ui.card
  .ui.feed
  > .event
  > .content.recentOrder
  label::after {
  content: none !important;
}

.multiContentListBox .ui.card .ui.feed > .event > .content.recentOrder label {
  color: #888 !important;
  font-size: 16px;
}

.ui.grey.header {
  color: #000 !important;
}

.listBoxTitle2 img {
  margin-left: 22px !important;
}

.listBoxTitle2 span {
  margin-top: 2px !important;
  display: block !important;
  font-size: 16px;
  float: right;
}

.ui.grid.calendarContainer {
  margin-left: -3.7rem;
  margin-right: -3.7rem;
}

.calendar .ui.list {
  border: 1px solid #dadada;
  border-radius: 8px;
  height: 48px;
  position: relative;
}

.calendar .ui.list .item img {
  border-radius: 0px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 48px;
  padding: 13px !important;
}

.calendar .ui.list .item .description {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -6px;
}

.calendar .ui.list .item h4 {
  line-height: 47px;
}

.calendar .ui.list {
  list-style-type: none;
  margin: 8px 0;
}

.calendar .ui.grid > .column:not(.row) {
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important;
}

.formMargin.class_schedule .ui.grid > [class*="three column"].row > .column {
  width: 16.5% !important;
}

.formMargin.month_schedule .ui.grid > .row > .column:first-child {
  margin-right: 2px;
}

.formMargin.month_schedule .ui.grid > .row > .column {
  width: 8.133333% !important;
  padding-right: 0.3rem;
  padding-left: 0.6rem;
}

.formMargin.month_schedule .radio-toolbar span {
  padding: 11px 0px !important;
}

.formMargin.month_schedule .ui.grid > .row > .column + .column {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

.formMargin.day_schedule .ui.grid > [class*="three column"].row > .column {
  width: 14.2% !important;
  padding-right: 0.5rem;
  padding-left: 0.8rem;
}

.formMargin.type_schedule .ui.grid > .row > .column {
  width: 33.333% !important;
  padding-right: 0.1rem !important;
}

.formMargin.type_schedule .ui.grid > .row > .column label span {
  padding: 11px 17px !important;
}

.formMargin.centers_schedule .ui.grid .column {
  width: 33.33333% !important;
  /*padding-right: 0.1rem!important;*/
}

.formMargin.centers_schedule .ui.checkbox input.hidden + label {
  text-decoration: none;
  color: #000;
}

.counterInput {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 8px;
}

.counterInput input {
  width: 67% !important;
  height: 40px !important;
}

.counterInput div > div:first-child,
.counterInput div > div:last-child {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 35px;
  color: #4075ff !important;
  color: var(--color-background) !important;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  user-select: none;
}

.counterInput div > div:first-child {
  border-right: 1px solid #dadada !important;
}

.counterInput div > div:last-child {
  border-left: 1px solid #dadada !important;
}

.formMargin .ui.input > textarea:-ms-input-placeholder {
  color: rgba(191, 191, 191, 0.87);
}

.formMargin .ui.input > textarea::placeholder {
  color: rgba(191, 191, 191, 0.87);
}

.counterInput input:active {
  border-color: rgba(0, 0, 0, 0.3) !important;
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.87) !important;
  box-shadow: none !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.counterInput input:focus {
  outline: none;
}

.profileimgContainer {
  width: 200px;
  height: 200px;
  margin: auto;
  border-radius: 200px;
  background: #dadada;
  background-size: cover;
  background-repeat: no-repeat;
}

.profileimgContainer .fileLabel {
  border-radius: 35px;
  width: 35px;
  margin: 2px;
  background: #4075ff !important;
  background: var(--color-background) !important;
  display: inline-block;
  height: 35px;
  line-height: 34px;
  text-align: center;
  font-size: 2rem;
  color: #ddd;
  margin-left: 35px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin-left: -17px;
}

.formMargin.user_management_role
  .ui.grid
  > [class*="three column"].row
  > .column {
  width: 25% !important;
  padding-right: 0.5rem;
  padding-left: 0.8rem;
}

.formMargin.user_management_role .radio-toolbar span {
  padding: 11px 2px !important;
}

.listBoxTitle2 span {
  font-size: 16px;
  position: absolute;
  right: 33px;
  top: -1px;
  font-weight: 800;
}

.merchandise .ui.card:hover {
  transform: translateY(0px);
}

/*.rbc-timeslot-group{
    min-height: 100px;
  }
  .rbc-time-content > * + * > * {
    height: 100px;
  }
  .rbc-header.rbc-today > a{
    background: blue;
    color: #fff;
    width: 20px;
    height: 40px;
    margin: auto;
    line-height: 7px;
    text-align: center;
    border-radius: 40px;
    padding: 11px;
  }
  .rbc-time-header-content .rbc-row.rbc-time-header-cell{
    min-height: 80px;
    line-height: 80px;
  }*/
.primaryBtn.Linker {
  line-height: 40px;
  text-align: center;
  width: auto !important;
  display: block;
  color: #fff !important;
}

.user-image-comms {
  width: 48px;
  height: 48px;
  border: 2px solid #4075ff;
  border-radius: 50px;
  background: #4075ff !important;
  object-fit: cover;
  object-position: top;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
.message-receiver {
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #3c73dc;
  color: #ffff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 400px;
  word-wrap: break-word;
  text-align: left;
  border: 1px solid #e6f4ff;
  border-radius: 10px;
  left: 70px;
  top: -25px;
}
.message-receiver:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid #3c73dc;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 0;
  left: -15px;
}
.infinite-scroll {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.infinite-scroll::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.newsFeed_Sroll .infinite-scroll-component {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.newsFeed_Sroll .infinite-scroll-component::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
/* .newsFeed_Sroll:hover {
  width: 20px;
} */
.newsFeed_Sroll .infinite-scroll-component {
  overflow: unset !important;
}

.comms-user-name {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #121941;
}
/* .infinite-scroll-component {
  overflow: unset !important;
} */
.call-icon {
  width: 48px;
  height: 48px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #4075ff;
  border-radius: 50%;
  cursor: pointer;
}

.ui.button2 {
  width: 150px;
}

.call-icon:hover {
  width: 48px;
  height: 48px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #4075ff;
  border-radius: 50%;
  cursor: pointer;
  filter: brightness(1.04) contrast(1.33) hue-rotate(-120deg) saturate(1.34);
  -webkit-filter: brightness(1.04) contrast(1.33) hue-rotate(-120deg)
    saturate(1.34);
}

.usercss {
  top: 180px;
  bottom: 10px;
  position: absolute;
  display: block;
  width: 92.5%;
  -ms-overflow-style: none;
  height: 500px;
}

@media screen and (max-width: 1152px) {
  /* .usercss {
    top: 180px;
    bottom: 10px;
    position: absolute;
    display: block;
    width: 92.5%;
    -ms-overflow-style: none;
    height: 500px;
  } */
}

.usercss::-webkit-scrollbar {
  display: none;
}
.usercss2 {
  top: 180px;
  bottom: 10px;
  position: absolute;
  /* overflow-y: scroll; */
  display: block;
  width: 92.5%;
  -ms-overflow-style: none;
  height: 500px;
  overflow-x: hidden;
}

.usercss2::-webkit-scrollbar {
  display: block;
}
.bottom_form {
  position: relative;
  top: 705px;
  left: 5px;
  background-color: transparent;
  width: 95%;
}
.sender {
  display: grid;
  place-items: end;
  color: #939494;
  margin-bottom: 10px;
}
.commsHeader {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  color: #4075ff;
}
.card-box {
}
.infinite-scroll-component__outerdiv {
  background-color: #fff !important;
}
.message-area {
  position: absolute;
  top: 100px;
  bottom: 70px;
  height: auto;
  width: 90%;
  overflow-y: auto;
  margin-top: 20px;
  overflow-x: hidden;
  display: inline-block;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  -ms-overflow-style: none;
}

.message-area::-webkit-scrollbar {
  display: none;
}

.message-receive {
  position: relative;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #4075ff;
  color: #fff;
  width: 200px;
  height: auto;
  text-align: left;
  border: 1px solid #4075ff;
  border-radius: 10px;
}

.message-receive:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid #4075ff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 0;
  left: -15px;
}

.message-receive:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 17px solid #4075ff;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  top: -1px;
  left: -17px;
}

.message-send {
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #e6f4ff;
  color: #3c73dc;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 400px;
  word-wrap: break-word;
  text-align: left;
  border: 1px solid #e6f4ff;
  border-radius: 10px;
}

.message-send:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid #e6f4ff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 0;
  right: -15px;
}

.message-send:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 17px solid #e6f4ff;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  top: -1px;
  right: -17px;
}

.message-text {
  position: fixed;
  bottom: 5px;
  padding: 0 !important;
  border-top: 1px solid #cccc;
  margin: 0 auto;
  min-width: 45%;
  display: table;
  padding: 0;
  height: 80px;
}

.custy {
  border: 1px solid #4075ff !important;
  background-color: #e6f4ff !important;
}

.suBtn {
  /* background-color: var(--color-background)!important; */
  /* font-size: 1.1rem!important; */
  border-radius: 50px !important;
  /* width: 236px; */
  /* height: 48px; */
  padding: 0 !important;
  position: relative !important;
  right: 15px !important;
}
.suBtn2 {
  /* background-color: var(--color-background)!important; */
  /* font-size: 1.1rem!important; */
  border-radius: 50px !important;
  /* width: 236px; */
  /* height: 48px; */
  padding: 0 !important;
  position: relative !important;
  right: 1.5vw !important;
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-left: 3px !important;
  margin-right: 10px;
}
.message-loader {
  position: absolute !important;
  top: 94% !important;
  left: 98% !important;
  z-index: 999999 !important;
}
.message-loader::after {
  content: "";
  border-width: 0.2em !important;
  border-color: #3c73dc transparent transparent !important;
}

.ui.three.cards .ui.card > img {
  position: absolute;
  right: 0;
  background: #4075ff !important;
  background: var(--color-background) !important;
  border: none;
  border-radius: 0 !important;
  border-top-right-radius: 8px !important;
  width: 40px;
  height: 40px;
  padding: 12px;
}

.ui.three.cards .ui.card {
  height: 100%;
  box-shadow: none !important;
}

.ui.three.cards .ui.card .imgContainer {
  background-size: cover;
  border-radius: 8px;
  background-position: center;
}

.formMargin .ui.three.cards {
  margin-left: -0.5em;
}

.formMargin .ui.three.cards > .card {
  width: calc(33.33333333% - 1em);
  margin-left: 0.4em;
  margin-right: 0.4em;
}

.orderedDetailPopup {
  padding: 31px 36px;
  width: 40%;
}

.orderedDetailPopup label {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
}

.orderedDetailPopup .closeBtn img {
  position: absolute;
  top: 24px;
  right: 24px;
}

.orderedDetailPopup .ui.card {
  width: 100%;
  height: 68px;
}

.orderedDetailPopup .content.recentOrder label {
  line-height: 13px;
}

.orderedDetailPopup .content.recentOrder label::after {
  content: "";
}

.delivered {
  color: #7cd227;
  color: var(--color-passed);
  font-weight: bold;
}
.ordered {
  color: #4075ff;
  color: var(--color-background);
  font-weight: bold;
}

/*{
    font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  }*/
/*.{
    width: 80px;
    height: 80px;
    border-radius: 4px;
  }*/
ul.orderInfo {
  list-style: none;
  font-weight: bold;
  line-height: 20px;
  position: relative;
  top: 5px;
}

.recentOrderDetail {
  border: 1px solid #ccc;
  border-radius: 8px;
}

.recentOrderDetail .ui.card {
  box-shadow: none;
  border-radius: 0;
}

.recentOrderDetail .aligned.list {
  padding-left: 13px;
  padding-right: 13px;
  border-bottom: 1px solid #eee;
}

.recentOrderDetail span.graytxt {
  float: right;
  color: gray;
  font-size: 20px;
}

.donateTitle {
  height: 200px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.donateTitle::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.donateBtnInactive {
  background-color: #fff !important;
  font-size: 1.1rem !important;
  border-radius: 20px !important;
  width: 125px;
  height: 40px;
}

.ui.list.simpleList {
  margin: 0.4em 0;
}

.simpleList .item {
  border: 1px solid #eee;
  padding: 4px 17px 4px 17px !important;
  border-radius: 8px;
}

.simpleList .item .content {
  line-height: 36px !important;
}

.longcard .content .chart {
  float: right;
  width: 120px;
  height: 100px;
}

.ui.modal .img {
  float: right !important;
  width: 24px;
  height: 24px;
  margin-top: 27px;
  margin-right: 24px;
}

.ui.modal .title {
  font-family: Open Sans;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  margin-top: 43px;
  margin-left: 40px;
}

.ui.modal .name {
  position: absolute;
  top: 68px;
  left: 85px;
  font-size: 14px;
  font-weight: 600;
  color: #1b1a1a;
}

.ui.modal .centre {
  position: absolute;
  width: 146px;
  height: 32px;
  padding-top: 3px;
  padding-left: 5px;
  background: linear-gradient(0deg, #4075ff, #4075ff), #ffffff;
  border-radius: 20px;
}

.ui.modal .centretext {
  position: absolute;
  width: 70px;
  height: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}

.ui.modal .class {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  padding: 4px;
  border-radius: 20px;
}

.ui.modal .datetext {
  position: absolute;
  height: 19px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  color: #1b1a1a;
}

.ui.modal .text {
  position: absolute;
  height: 76px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #1b1a1a;
}

.listBox .listimage {
  width: 36px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 20px;
}

.formMargin.icon_img_schedule .radio-toolbar span {
  padding: 6px !important;
}

.imgCont {
  width: 100%;
  height: 34px;
  /*line-height: 242px!important;*/
  text-align: center;
  vertical-align: top;
  background-size: contain;
  border-radius: 8px;
}

.fileContainer {
  width: 100%;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  background-size: contain;
  border-radius: 8px;
  border-radius: 8px;
}

.schedult_item_list .ui.image {
  height: 100px;
  width: 100px;
}

.input-ic-commom {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-top: -30px;
  color: #4075ff !important;
  color: var(--color-background) !important;
}

.input-ic-commom.left {
  left: 26px;
}
.a_l {
  left: 0px;
}
.input-ic-commom.right {
  right: 26px;
}

.multi {
  width: 100%;
}

.bgRed {
  background-color: red;
}
.bgBlue {
  background-color: #4075ff;
  background-color: var(--color-background);
}

.staticBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.nice-dates-day:before {
  background-color: white !important;
}
.nice-dates-day:after {
  border: none !important;
}
.nice-dates-day.-selected:not(:hover) *,
.nice-dates-day.-selected.-disabled * {
  color: #000 !important;
}
.exit_modal {
  position: absolute;
  right: 30px;
  top: 10px;
  z-index: 999999;
  color: #fff !important;
  background-color: blue !important;
  width: 50px !important;
  height: 50px !important;
  padding: 0 !important;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  line-height: 50px;
  cursor: pointer;
}
.overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.85) !important;
  z-index: 999998 !important;
}
.overlay_content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100vw !important;
  height: 100vh !important;
  background-color: none !important;
}
.modal_img {
  background-color: none !important;
  box-shadow: none !important;
}
.noBottomMargin2 {
  background-color: red !important;
}
.sendcss {
  bottom: -50px;
  position: absolute;
  width: 100%;
  display: inherit;
  left: 10px;
  z-index: 111;
}

.payment-verification-dropdown .menu.transition div {
  height: 40px !important;
}
.modal_dropdown .menu.transition div {
  height: 40px !important;
  position: relative;
  z-index: 999999999999999;
}

.radio-toolbar.activeBg .ui.checked + span {
  background: linear-gradient(0deg, #e6f1fc, #e6f1fc), #ffffff !important;
  color: #4075ff !important;
  border: 1px solid #4075ff;
}
.radio-toolbar.activeBg .ui + span {
  background-color: #f6f6f6 !important;
  color: #939494 !important;
}

.calendar_filter {
  width: 25% !important;
}

.ui.segment.listbox-border:hover {
  background-color: #bfd7ff;
  cursor: "pointer";
}

.react-datepicker__time-list-item--disabled {
  display: none;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  position: absolute;
  top: -5px;
  left: 100px;
  background-color: #4075ff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
}
.tooltiptext {
  visibility: hidden;
  position: absolute;
  top: -5px;
  left: 100px;
  background-color: #4075ff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  z-index: 1;
}

.form-question-container {
  border: 1px solid #dadada;
  border-radius: 8px;
  background-color: #f9fbff;
  padding: 16px 0px 0px 0px;
  margin-bottom: 40px;
}

.form-question-mandatory-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-top: 1px solid #dadada;
}

.form-question-add-link-container {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #4075ff;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 184px;
}
.form-question-add-link {
  width: auto;
  cursor: pointer;
}
.mh16 {
  margin: 0px 16px 0px 16px;
}
.mh24 {
  margin: 0px 24px 0px 24px;
}
.ml15 {
  margin-left: 15px;
}
.mr8 {
  margin-right: 8px;
}
.mr13 {
  margin-right: 13px;
}
.mr20 {
  margin-right: 20px;
}
.mb14 {
  margin-bottom: 14px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb23 {
  margin-bottom: 23px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb32 {
  margin-bottom: 32px;
}
.mt-7 {
  margin-top: -7px;
}
.mt8 {
  margin-top: 8px;
}
.mt14 {
  margin-top: 14px;
}
.mt16 {
  margin-top: 16px;
}
.mt19 {
  margin-top: 19px;
}
.mt23 {
  margin-top: 23px;
}
.p0 {
  padding: 0px;
}
.pl10 {
  padding-left: 10px;
}
.ph16 {
  padding: 0px 16px 0px 16px;
}
.pv13 {
  padding: 13px 0px;
}
.pl9 {
  padding-left: 9px;
}
.pl14 {
  padding-left: 14px;
}
.pl16 {
  padding-left: 16px;
}
.pl24 {
  padding-left: 24px;
}
.pr2 {
  padding-right: 2px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.pr16 {
  padding-right: 16px;
}
.pr24 {
  padding-right: 24px;
}
.pt24 {
  padding-top: 24px;
}
.pb24 {
  padding-bottom: 24px;
}
.flex {
  display: flex;
}
.flex-align-center {
  align-items: center;
}
.flex-justify-space-between {
  justify-content: space-between;
}
.w16 {
  width: 16px;
}
.w20 {
  width: 20px;
}
.w21 {
  width: 21px;
}
.w24 {
  width: 24px;
}
.w32 {
  width: 32px;
}
.w200 {
  width: 200px;
}
.wfull {
  width: 100%;
}
.whalf {
  width: 50%;
}
.h20 {
  height: 20px;
}
.h44 {
  height: 44px;
}
.fs12 {
  font-size: 12px;
}
.fs14 {
  font-size: 14px;
}
.fs16 {
  font-size: 16px;
}
.fs28 {
  font-size: 28px;
}
.fs36 {
  font-size: 36px;
}
.fw400 {
  font-weight: 400;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.pointer {
  cursor: pointer;
}
#disabled-input {
  background-color: #f6f6f6;
  width: 100%;
  height: 44px;
  padding-left: 18px;
  border-radius: 8px;
  border: 1px solid #dadada;
}
#disabled-input:-ms-input-placeholder {
  color: #939494;
  font-weight: 400;
}
#disabled-input::placeholder {
  color: #939494;
  font-weight: 400;
}
.border-light {
  border: 1px solid #d8d8d8;
}
.border-light2 {
  border: 1px solid #dadada;
}
.border-light3 {
  border: 2px solid #dadada;
}
.br8 {
  border-radius: 8px;
}
.br20 {
  border-radius: 20px;
}
.circle {
  box-sizing: border-box;
  border-radius: 50%;
}
.square {
  border-radius: 4px;
}
.text-ar {
  text-align: right;
}
.text-ac {
  text-align: center;
}
.text-primary1 {
  color: #1b1a1a;
}
.text-blue {
  color: #4075ff;
}
.text-notFound {
  color: #becae4;
}
.text-white {
  color: white;
}
.text-gray {
  color: #939494;
  color: var(--color-gray);
}
.text-turncation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-underline {
  text-decoration: underline;
}
.pos-abs {
  position: absolute;
}
.top20 {
  top: 20px;
}
.form-inner-container {
  width: 600px;
  margin: auto;
}
.range_input input {
  background-color: #e6f1fc !important;
  border-radius: 8px !important;
  border: 1px solid #4075ff !important;
  color: #4075ff !important;
  font-weight: 700 !important;
  width: 600px !important;
}
.scrollWidth {
  width: 27.25% !important;
}
.full-container {
  width: 100%;
  height: 100%;
  background-color: #f9fbff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.css-1rhbuit-multiValue {
  border: 1px solid #dadada !important;
  border-radius: 5px !important;
  background-color: #e0e0e0 !important;
}
.css-12jo7m5 .select__multi-value__label {
  color: rgba(0, 0, 0, 0.8) !important;
}
.css-xb97g8:hover {
  background-color: transparent !important;
  color: #333 !important;
}

.burger-menu {
  display: none;
}

@media screen and (max-width: 850px) {
  .attendance-list-container {
    overflow: auto;
    margin-top: 10px;
    white-space: nowrap;
  }
  .text-truncation-850 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .width-900 {
    width: 900px;
  }
}

@media screen and (max-width: 1152px) {
  .burger-menu {
    position: relative;
    display: block;
    width: 24px;
    cursor: pointer;
  }
  #side-menu {
    width: 0px !important;
    padding: 0px;
    z-index: 1;
  }
  #main-content-width {
    margin-left: 0px;
  }
}

.only-tablet {
  /* display: none; */
}

@media screen and (max-width: 1152px) and (min-width: 768px) {
  .only-tablet {
    display: block;
  }
  .ui.segment.user-panel {
    padding-left: 10px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.upload-file {
  color: #4075ff;
  color: var(--color-background);
}

.upload-file:hover {
  background-color: #e6f1fc;
}

#file-upload::-webkit-file-upload-button {
  visibility: hidden;
}
#file-upload::before {
  content: "Upload PDF file";
  display: inline-block;
  border: 1px solid #4075ff;
  border-radius: 20px;
  padding: 13px 34px 14px 66px;
  margin-top: 16px;
  outline: none;
  white-space: nowrap;
  /* -webkit-user-select: none; */
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 600;
  color: #4075ff;
  color: var(--color-background);
  margin-bottom: 46px;
}

.rmdp-container {
  width: 95%;
}

.rmdp-input {
  width: 100% !important;
  height: 44px !important;
  border: none !important;
}

.rmdp-input:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

:root {
  --color-background: #4075ff;
  --color-general: #f9fbff;
}
html,
body,
* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ui.segment.loaderWrapper {
  top: 0;
  bottom: 0;
  position: absolute !important;
  right: 0;
  left: 0;
  border: none !important;
  border-radius: 0;
}
.ui.header,
h1,
h2,
h3,
h4,
h5,
button,
input,
optgroup,
select,
textarea {
  font-family: "Open Sans" !important;
}
.ui.fluid.dropdown,
.ui.fluid.input > input,
.react-datepicker__input-container input[type="text"],
.ui.right.labeled.input.traveller,
.ui.input.search,
.ui.input.search input {
  border-radius: 8px;
  height: 44px;
  line-height: 19px;
}
.primaryBtn img {
  display: inline-block !important;
  margin-right: 8px;
  width: 13px;
  margin-top: -2px;
}
.ui.segment {
  box-shadow: 1px 0px 2px 0 rgba(34, 36, 38, 0.15) !important;
}
.ui.grey.header {
  color: #939494 !important;
}

h6.ui.grey.header {
  font-size: 12px;
}
.activities .listBox h6 {
  position: absolute;
}
.activities .listBox img,
.activities .listBox p {
  display: inline-block !important;
}
.activities .listBox .column {
  height: 53px;
  line-height: 53px;
}
.activities .listBox span {
  display: block;
  line-height: 44px;
}
.ui.tiny.progress {
  position: absolute;
  margin-top: -8px !important;
  width: 80%;
}
html,
body,
#root,
#root > div,
#root > div > div {
  height: 100%;
  margin: 0;
}
.main-content-width > div > div > div,
.main-content-width > div > div > div + div,
.main-content-width .merchandise > div > div {
  background-color: #f9fbff !important;
  background-color: var(--color-general) !important;
  padding: 60px 40px !important;
}
@media screen and (max-width: 1152px) {
  .main-content-width > div > div > div,
  .main-content-width > div > div > div + div,
  .main-content-width .merchandise > div > div {
    background-color: #f9fbff !important;
    background-color: var(--color-general) !important;
    padding: 0px 40px 60px 40px !important;
    z-index: 0;
  }
}
.main-content-width .calendar > div > div.four.addwhitebg {
  background-color: #fff !important;
  padding: 60px 17px 26px 26px !important;
}
.main-content-width .merchandise > div > div + div,
.main-content-width > div > div > div + div,
.main-content-width .calendar > div > div + div {
  background: #fff !important;
}
.user_management .ui.grid.adjustWidth > [class*="three wide"] {
  width: 19.55% !important;
}
.ui.grid.fullHeight > .row .subtitle {
  font-size: 16px !important;
  font-weight: bold;
}
.calendar .ui.list .item h4 {
  font-size: 14px !important;
}
.main-content-width .merchandise > div > div.addwhitebg {
  padding-top: 114px !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
}
/*.main-content-width .calendar > div > div{
  padding-left: 0x!important;
  padding-right: 0px!important;
}*/
.main-content-width > div > div > div.visible.dimmer {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.ui.message {
  padding: 0.8em 1.5em !important;
}
.ui.segment.loaderWrapper .ui.dimmer {
  position: fixed !important;
}
.fullHeight .ui.active.dimmer {
  opacity: 0.4;
}
.ui.page.modals.dimmer {
  background-color: rgba(0, 0, 0, 0.3);
}
.autoWidth {
  width: auto !important;
}
.primaryBtn.autoWidth {
  display: block;
  margin-bottom: 22px;
}
.rbc-timeslot-group {
  min-height: 100px;
}
.ui.grid > .row > .column.imgbar {
  padding-right: 0.14rem !important;
}
.ui.grid > [class*="three column"].row > .column {
  width: auto !important;
}
.ui.grid.calendar .ui.button {
  padding: 0.78571429em 0em 0.78571429em;
}
.rbc-month-view {
  border: 1px solid #fff !important;
}
.holidayCToolBar {
  list-style: none;
  display: inline-flex;
  text-align: center;
  padding: 0;
  margin: auto;
  margin-bottom: 26px;
}
.holidayCToolBar li {
  margin: 0 8px;
}
.ui.segment.holidayCtrl {
  /* text-align: right; */
  border-radius: 18px;
  padding: 12px 12px 8px 22px;
  /* margin-left: 15px; */
  /* margin-left: auto; */
}
.WeekCalendar .holidayCToolBar {
  border: 1px solid #ddd;
  line-height: 44px;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  margin-right: 100px;
}
.holidayCToolBar li:nth-child(2) {
  width: 68%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.main-content-width > div > div > div .ui.list.relaxed {
  margin-left: -26px;
  margin-right: -26px;
}
.calendar > div > div h5.ui.header::before {
  content: "";
  background-color: #4075ff !important;
  background-color: var(--color-background) !important;
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  border-radius: 20px;
  margin-top: 4px;
  margin-right: 8px;
  margin-left: -22px;
}
.rbc-header a {
  line-height: 50px;
  display: block;
  height: 120px;
}
.rbc-header a span {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin: 10px auto;
}
.rbc-header.rbc-today a span {
  background-color: #4075ff !important;
  border-radius: 50px;
  color: #fff;
}
.merchandise .five.addwhitebg .react-datepicker__input-container input {
  padding-left: 44px !important;
  font-size: 0.95em !important;
}
.merchandise .five.addwhitebg .to .react-datepicker__input-container input {
  padding-left: 35px !important;
  font-size: 0.95em !important;
}
.merchandise .five.addwhitebg .react-datepicker-popper,
.merchandise .five.addwhitebg .to .react-datepicker-popper {
  left: -44px !important;
}
.ui.small.editScheduleModal {
  text-align: left;
  padding: 10px 40px 40px !important;
}
.rbc-row-segment .rbc-event {
  background: gold;
  margin: auto;
  padding: 5.3px;
  width: 10%;
}
.modals.dimmer .ui.scrolling.editScheduleModal.modal {
  padding: 40px;
}
.radio-toolbar input {
  display: none;
}
/*.radio-toolbar .ui.checked+span{
  background: #e6f1fc;
  border: 1px solid #065CA4;
  color: #065CA4;
}*/
.classGp {
  display: flex;
  border-radius: 8px;
  border: 1px solid #065ca4;
  overflow: hidden;
}
.classGp .radio-toolbar span {
  display: inline-block !important;
  padding: 10px 14px !important;
  cursor: pointer !important;
  width: 100%;
  text-align: center;
  border-radius: 0 !important;
  border: 0;
  border-left: 1px solid #ddd !important;
}
.checked {
  background: #e6f1fc;
  color: #065ca4;
}
.classGp .radio-toolbar span:first-child {
  border-left: none !important;
}
.nutrition_img {
  width: 50px;
  height: 50px;
  border-radius: 10px !important;
  margin-right: 10px;
  object-fit: cover;
}

