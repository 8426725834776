@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
:root {
  --color-background: #4075ff;
  --color-general: #f9fbff;
}
html,
body,
* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ui.segment.loaderWrapper {
  top: 0;
  bottom: 0;
  position: absolute !important;
  right: 0;
  left: 0;
  border: none !important;
  border-radius: 0;
}
.ui.header,
h1,
h2,
h3,
h4,
h5,
button,
input,
optgroup,
select,
textarea {
  font-family: "Open Sans" !important;
}
.ui.fluid.dropdown,
.ui.fluid.input > input,
.react-datepicker__input-container input[type="text"],
.ui.right.labeled.input.traveller,
.ui.input.search,
.ui.input.search input {
  border-radius: 8px;
  height: 44px;
  line-height: 19px;
}
.primaryBtn img {
  display: inline-block !important;
  margin-right: 8px;
  width: 13px;
  margin-top: -2px;
}
.ui.segment {
  box-shadow: 1px 0px 2px 0 rgba(34, 36, 38, 0.15) !important;
}
.ui.grey.header {
  color: #939494 !important;
}

h6.ui.grey.header {
  font-size: 12px;
}
.activities .listBox h6 {
  position: absolute;
}
.activities .listBox img,
.activities .listBox p {
  display: inline-block !important;
}
.activities .listBox .column {
  height: 53px;
  line-height: 53px;
}
.activities .listBox span {
  display: block;
  line-height: 44px;
}
.ui.tiny.progress {
  position: absolute;
  margin-top: -8px !important;
  width: 80%;
}
html,
body,
#root,
#root > div,
#root > div > div {
  height: 100%;
  margin: 0;
}
.main-content-width > div > div > div,
.main-content-width > div > div > div + div,
.main-content-width .merchandise > div > div {
  background-color: var(--color-general) !important;
  padding: 60px 40px !important;
}
@media screen and (max-width: 1152px) {
  .main-content-width > div > div > div,
  .main-content-width > div > div > div + div,
  .main-content-width .merchandise > div > div {
    background-color: var(--color-general) !important;
    padding: 0px 40px 60px 40px !important;
    z-index: 0;
  }
}
.main-content-width .calendar > div > div.four.addwhitebg {
  background-color: #fff !important;
  padding: 60px 17px 26px 26px !important;
}
.main-content-width .merchandise > div > div + div,
.main-content-width > div > div > div + div,
.main-content-width .calendar > div > div + div {
  background: #fff !important;
}
.user_management .ui.grid.adjustWidth > [class*="three wide"] {
  width: 19.55% !important;
}
.ui.grid.fullHeight > .row .subtitle {
  font-size: 16px !important;
  font-weight: bold;
}
.calendar .ui.list .item h4 {
  font-size: 14px !important;
}
.main-content-width .merchandise > div > div.addwhitebg {
  padding-top: 114px !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
}
/*.main-content-width .calendar > div > div{
  padding-left: 0x!important;
  padding-right: 0px!important;
}*/
.main-content-width > div > div > div.visible.dimmer {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.ui.message {
  padding: 0.8em 1.5em !important;
}
.ui.segment.loaderWrapper .ui.dimmer {
  position: fixed !important;
}
.fullHeight .ui.active.dimmer {
  opacity: 0.4;
}
.ui.page.modals.dimmer {
  background-color: rgba(0, 0, 0, 0.3);
}
.autoWidth {
  width: auto !important;
}
.primaryBtn.autoWidth {
  display: block;
  margin-bottom: 22px;
}
.rbc-timeslot-group {
  min-height: 100px;
}
.ui.grid > .row > .column.imgbar {
  padding-right: 0.14rem !important;
}
.ui.grid > [class*="three column"].row > .column {
  width: auto !important;
}
.ui.grid.calendar .ui.button {
  padding: 0.78571429em 0em 0.78571429em;
}
.rbc-month-view {
  border: 1px solid #fff !important;
}
.holidayCToolBar {
  list-style: none;
  display: inline-flex;
  text-align: center;
  padding: 0;
  margin: auto;
  margin-bottom: 26px;
}
.holidayCToolBar li {
  margin: 0 8px;
}
.ui.segment.holidayCtrl {
  /* text-align: right; */
  border-radius: 18px;
  padding: 12px 12px 8px 22px;
  /* margin-left: 15px; */
  /* margin-left: auto; */
}
.WeekCalendar .holidayCToolBar {
  border: 1px solid #ddd;
  line-height: 44px;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  margin-right: 100px;
}
.holidayCToolBar li:nth-child(2) {
  width: 68%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.main-content-width > div > div > div .ui.list.relaxed {
  margin-left: -26px;
  margin-right: -26px;
}
.calendar > div > div h5.ui.header::before {
  content: "";
  background-color: var(--color-background) !important;
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  border-radius: 20px;
  margin-top: 4px;
  margin-right: 8px;
  margin-left: -22px;
}
.rbc-header a {
  line-height: 50px;
  display: block;
  height: 120px;
}
.rbc-header a span {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin: 10px auto;
}
.rbc-header.rbc-today a span {
  background-color: #4075ff !important;
  border-radius: 50px;
  color: #fff;
}
.merchandise .five.addwhitebg .react-datepicker__input-container input {
  padding-left: 44px !important;
  font-size: 0.95em !important;
}
.merchandise .five.addwhitebg .to .react-datepicker__input-container input {
  padding-left: 35px !important;
  font-size: 0.95em !important;
}
.merchandise .five.addwhitebg .react-datepicker-popper,
.merchandise .five.addwhitebg .to .react-datepicker-popper {
  left: -44px !important;
}
.ui.small.editScheduleModal {
  text-align: left;
  padding: 10px 40px 40px !important;
}
.rbc-row-segment .rbc-event {
  background: gold;
  margin: auto;
  padding: 5.3px;
  width: 10%;
}
.modals.dimmer .ui.scrolling.editScheduleModal.modal {
  padding: 40px;
}
.radio-toolbar input {
  display: none;
}
/*.radio-toolbar .ui.checked+span{
  background: #e6f1fc;
  border: 1px solid #065CA4;
  color: #065CA4;
}*/
.classGp {
  display: flex;
  border-radius: 8px;
  border: 1px solid #065ca4;
  overflow: hidden;
}
.classGp .radio-toolbar span {
  display: inline-block !important;
  padding: 10px 14px !important;
  cursor: pointer !important;
  width: 100%;
  text-align: center;
  border-radius: 0 !important;
  border: 0;
  border-left: 1px solid #ddd !important;
}
.checked {
  background: #e6f1fc;
  color: #065ca4;
}
.classGp .radio-toolbar span:first-child {
  border-left: none !important;
}
.nutrition_img {
  width: 50px;
  height: 50px;
  border-radius: 10px !important;
  margin-right: 10px;
  object-fit: cover;
}
